$theme-colors: (
    "primary": blue,
    "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

*{
    margin: 0;
    box-sizing: border-box;
}

// body {
//     padding-top:56px;
// }
.footer {
    margin-top:3rem;
}
.navbar {
    border-bottom:1px solid #eee;
}


// my custom Styles:
header{
    display: flex;
    background-color: #E7F5F6;
}
header h5{
    color: #8B8F8F;
}

.btn{
    min-width: 10rem;
    // max-width: 10rem;
}
.btn-language select{
    border: 1px solid #6cbcc9;
    border-radius: .2rem .2rem 0rem 0;
    background-color: transparent;
    position: absolute;
    right: 1rem;
    top: 2.2rem;
}

.btn-login{
    display: none;
}

.user-form .form{
    margin: 3rem auto 0;
    display: flex;
    flex-direction: column;
    max-width: 25rem;
}

.user-form input{
    margin: .5rem;
    border: none;
    border-bottom: 1px solid #F4F4F4;
    // min-width: 18rem;
}

.user-form button{
    // min-width: 40%;
    margin: 1.2rem;
    padding: .3rem;
    border: .5px solid #1999AD;
    border-radius: .8rem;
    background-color: #1999AD;
    // border-color: #1999AD;
    color: white;
}

.btn-flylinkers-modal{
    border: 0.5px solid #1999AD;
    background-color: #1999AD;
    color: white;
}
.btn-flylinkers-modal:hover{
    background-color: #1a7e8e;
    color: white;
}
.btn-flylinkers-close-modal{
    background-color: #d0d0d0;
}


.user-form a{
    // color: #8B8F8F;
    // text-decoration: none;
    // margin-top: .5rem;
}

.sign-up input{
    margin: .5rem 0;
    border: none;
    border-radius: .4rem;
    background-color: #E2E2E2;
    padding: .5rem;
}
.sign-up div input, .userName{
    width: 49%;
}
.sign-up div input[type="checkbox"]{
    width: inherit;
}
.sign-up div input[type="email"], .sign-up div input[type="file"]{
    width: 100%;
}

.modal-footer{
    flex-wrap: nowrap;
}
.modal-footer .close{
    background-color: #a3a3a3;
    color: #fff;
}
.modal-footer .close:hover{
    background-color: #8b8f8f;
}
.modal-footer .save-user{
    background-color: #1999AD;
    color: #fff;
}
.modal-footer .save-user:hover{
    background-color: #138091;
}

.namesInput, .passwordInput, .contactIput{
    display: flex;
    justify-content: space-between;
}
.password-description{
    color: #8b8f8f;
    font-size: .8rem;
}

.btn-login-form{
    max-width: 15rem;
    min-width: 90%;
    margin: 1.2rem auto 0 !important;
}

.Google-button{
    font-weight: 500;
    background-color: transparent !important;
    color: #8b8f8f !important;
    border: 2px solid #E2E2E2 !important;
    display: flex;
    justify-content: center;
}
.Google-button span{
    margin: 0 .5rem;
}
.Google-button i{
    color: #000;
    font-size: 24px;
    margin-right: .5rem;
}

.homepage p{
    display: none;
}

.information{
    color: #8B8F8F;
}

.button-panel{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}
.button-panel a{
    margin: .3rem;
    background-color: #fff;
    border: 1px solid #6cbcc9;
    text-decoration: none;
    color: #8B8F8F;
    padding: .5rem .8rem;
    border-radius: .5rem;
    min-width: 100%;
}
.btn-join button{
    margin: 0;
}

footer{
    // background-color: #F4F4F4;
    color: #8B8F8F;
}
h3{
    color: #1999AD;
}


//pages => about - galery hall
.pages-section{
    background-color: #E7F5F6;
    // padding: 5rem;
    padding-top: 0;

}
.pages-section p{
    text-align: justify;
}

.about-section img{
    margin-bottom: -1rem;
}

.card-content{
    border: none;
    align-items: center;
    margin: 5rem 0;
    width: 9rem;
}
.card-body{
    flex-direction: column;
}
.card-body p{
    color: #1999AD;
    margin: 0;
    font-size: 500;
}
.card-text{
    display: none;
}

.container-img-gallery{
    border: 1px solid #1999AD;
    border-radius: 50%;
    padding: .5rem;
    width: 75%;
    margin-top: -50%;
}
.container-img-gallery img{
    border-radius: 50%;
    height: 6rem;
    object-fit: cover;
}


//terms
.terms-text-main {
    p{
        text-align: justify;
        margin: 0;
    }
    h4, h6{
        margin: 1rem 0;
        font-weight: 600;
    }
    ul{
        margin: 1rem 0;
    }
}

// create user errors
.userEmailUserName .inputError{
    border: 2px solid red;
}
.textError{
    color: red;
    font-weight: bold;
}

.muted{
    color: #8B8F8F;
}


@media screen and (min-width: 768px){
    //header:
    header{
        height: 8rem;
        // background-color: #fff;
    }

    .header-desktop{
        width: 100%;
        flex-wrap: inherit;
        align-items: center;
        justify-content: space-around;
    }

    .logo{
        display: block !important;
    }

    .slogan{
        display: none !important;
    }

    .btn-language select{
        position: inherit;
    }

    .btn-login{
        display: block;
        width: auto;
        margin: 0 .5rem;
    }

    .btn-login button{
        margin: 0 .5rem;
    }

    //homepage section
    .homepage p{
        display: block;
        color: #239FA8;
        font-size: 24px;
        margin-bottom: -2rem;
    }

    .user-form{
        display: block !important;
    }
    
    .user-form input{
        background-color: #E2E2E2;
        padding-left: 1rem;
        border-radius: .4rem;
        height: 2rem;
    }

    .btn-join{
        display: none;
    }

    .btns-header{
        display: flex !important;
        justify-content: flex-end;
    }

    .img-container{
        display: flex;
        justify-content: center;
        margin: auto;
        height: 23rem;
    }

    .img-pilots{
        width: inherit;
        img{
            height: 90%
        }
    }

    .information{
        background-color: #e7f5f6;
    }
    .information a{
        text-decoration: none;
    }
    .information h2{
        min-height: 5rem;
    }
    .information button{
        font-weight: 500;
        border: 2px solid #6cbcc9;
        // min-width: 6rem;
    }
    .information img{
        height: 20rem;
        margin-top: -13rem;
        margin-left:-2rem;
    }
    

    .container-text-us{
        flex-direction: column;
        align-content: flex-end;
        justify-content: center;
        text-align: justify;
    }

    .container-buttons-img{
        display: flex;
        flex-direction: row-reverse;
    }
    
    .button-panel{
        // margin: -9rem auto;
        max-width: 30rem;
    }

    .button-panel a{
        min-width: 10rem;
    }


    footer p{
        color: #6cbcc9;
        font-weight: 500;
    }


    //section pages
    .card-content-main{
        width: 18rem !important;
    }

    .card-content{
        width: 13rem;
    }
    .card-text{
        display: block;
    }
    .card-visible{
        display: block !important;
    }
    .card-hidden{
        display: none !important;
    }

    .container-img-gallery-main img{
        height: 13rem !important;
    }
    .container-img-gallery img{
        height: 9rem;
    }


    .about-section img{
        width: 300px;
    }
}

@media screen and(max-width:768px) {
    // .btn{
    //     min-width: 10rem;
    // }
}




// LOADER
.loader {
    display: flex;
    align-self: center;
    margin-bottom: 0.5rem;

    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #1999AD;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}